import { CalendarActions } from '../actions/calendarActions';
import { IAppointment } from '../model/IAppointment';

type CalendarState = {
  appointments: IAppointment[];
  loading: boolean;
  error: string | null;
};

const initialState: CalendarState = {
  appointments: [],
  loading: true,
  error: null,
};

const calendarReducer = (
  state: CalendarState = initialState,
  action: CalendarActions,
) => {
  switch (action.type) {
    case 'FETCH_APPOINTMENTS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_APPOINTMENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        appointments: action.payload,
      };
    case 'FETCH_APPOINTMENTS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default calendarReducer;
