import {
  Action,
  combineReducers,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import authReducer from '../../components/login/reducers/authReducer';
import store from './index';
import calendarReducer from '../../components/calendar/reducers/calendarReducer';
import { FetchAppointmentsActions } from '../../components/calendar/actions/fetchAppointmentsActions';
import { AuthActions } from '../../components/login/actions/authActions';

const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppAction = AuthActions | FetchAppointmentsActions;
export type AppDispatch = ThunkDispatch<AppState, unknown, AppAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  AppState,
  unknown,
  Action<string>
>;

export default rootReducer;
