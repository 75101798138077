import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import GlosslyRoutes from './components/route/GlosslyRoutes';

const App: React.FC = () => {
  return (
    <>
      <nav>{/*	navbar*/}</nav>
      <BrowserRouter>
        <GlosslyRoutes />
      </BrowserRouter>
      <footer>{/*	footer*/}</footer>
    </>
  );
};

export default App;
