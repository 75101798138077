import React, { useState } from 'react';
import './WorkInProgress.css';
import LoginModal from '../login/LoginModal'; // If you have props, define them here

// If you have props, define them here
interface WorkInProgressProps {}

const WorkInProgress: React.FC<WorkInProgressProps> = () => {
  const imagePath = 'workinprogress.jpg';
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={'containerStyle'}>
      <button
        className={'loginButtonStyle buttonStyle'}
        onClick={() => setIsModalOpen(true)}
      >
        Login
      </button>
      {isModalOpen && (
        <LoginModal
          onModalClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      <img
        src={imagePath}
        alt="Work in Progress"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      <p className={'paragraphStyle'}>
        This section is under development. Please check back later!
      </p>
    </div>
  );
};

export default WorkInProgress;
