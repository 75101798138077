import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
  exp: number;
}

const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true;

  try {
    const decoded: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return true;
  }
};

export { isTokenExpired };
