import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks/hooks';
import { Calendar } from 'react-big-calendar';
import { useLocalizer } from '../../app/hooks/calendar-hooks';
import ContentContainer from '../../atoms/ContentContainer';
import { fetchAppointments } from './actions/fetchAppointmentsActions';
import { useAxios } from '../../services/axios';
import { IAppointmentEvent } from './model/IAppointmentEvent';
import { IAppointmentResource } from './model/IAppointmentResource';
import { mapToEvents, mapToResources } from './mapper/appointmentMapper';
import { AppDispatch } from '../../app/store/root-reducer';

const CalendarComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const localizer = useLocalizer();
  const axiosInstance = useAxios();

  const { isLogged } = useAppSelector((state) => state.auth);
  const { appointments, loading, error } = useAppSelector(
    (state) => state.calendar,
  );
  const events: IAppointmentEvent[] = useMemo(
    () => mapToEvents(appointments),
    [appointments],
  );
  const resources: IAppointmentResource[] = useMemo(
    () => mapToResources(appointments),
    [appointments],
  );

  useEffect(() => {
    if (!isLogged) {
      navigate('/');
    } else {
      dispatch(fetchAppointments(axiosInstance));
    }
  }, [axiosInstance, dispatch, isLogged, navigate]);

  const handleLogout = () => {
    dispatch({
      type: 'LOGOUT',
    });
    navigate('/');
  };

  return (
    <ContentContainer>
      <h2>You are now logged in</h2>
      {loading && (
        <p id="calendar-loading">
          Loading events... (Loading spinner in the future)
        </p>
      )}
      {error && <p>Error loading events: {error}</p>}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor={(event) => new Date(event.date_from)}
        endAccessor={(event) => new Date(event.date_to)}
        resourceAccessor="group_id"
        resourceIdAccessor="group_id"
        resources={resources}
        resourceTitleAccessor="group_name"
        style={{ height: 500 }}
      />
      <button
        className={'buttonStyle'}
        style={{ marginTop: 20 }}
        onClick={handleLogout}
      >
        Log Out
      </button>
    </ContentContainer>
  );
};

export default CalendarComponent;
