import { AxiosInstance, AxiosResponse } from 'axios';
import { IAppointment } from '../model/IAppointment';
import { AppThunk } from '../../../app/store/root-reducer';

export interface IFetchAppointmentsRequestAction {
  readonly type: 'FETCH_APPOINTMENTS_REQUEST';
}

export interface IFetchAppointmentsSuccessAction {
  readonly type: 'FETCH_APPOINTMENTS_SUCCESS';
  payload: IAppointment[];
}

export interface IFetchAppointmentsFailureAction {
  readonly type: 'FETCH_APPOINTMENTS_FAILURE';
  error: string;
}

export const fetchAppointments =
  (axiosInstance: AxiosInstance): AppThunk<AxiosResponse<IAppointment[]>> =>
  async (dispatch) => {
    dispatch({ type: 'FETCH_APPOINTMENTS_REQUEST' });
    try {
      const response = await axiosInstance.get('/api/v1/appointments');
      dispatch({ type: 'FETCH_APPOINTMENTS_SUCCESS', payload: response.data });
      return response;
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch({ type: 'FETCH_APPOINTMENTS_FAILURE', error: errorMessage });
      return Promise.reject(new Error(errorMessage));
    }
  };

export type FetchAppointmentsActions =
  | IFetchAppointmentsRequestAction
  | IFetchAppointmentsSuccessAction
  | IFetchAppointmentsFailureAction;
