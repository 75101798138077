import { Route, Routes } from 'react-router-dom';
import CalendarComponent from './CalendarComponent';
import React from 'react';

export enum CalendarPaths {
  ROOT = '',
}

const CalendarRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={CalendarPaths.ROOT} element={<CalendarComponent />} />
    </Routes>
  );
};

export default CalendarRoutes;
