import axios from 'axios';
import { isTokenExpired } from '../utils/jwtUtils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks/hooks';
import { useMemo } from 'react';

export const useAxios = () => {
  const { refreshToken, accessToken } = useAppSelector((state) => state.auth);
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  return useMemo(() => {
    const axiosApiInstance = axios.create({
      baseURL: `${process.env.REACT_APP_GLOSSLY_API_BASE_URL}/${process.env.REACT_APP_GLOSSLY_API_VERSION}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    axiosApiInstance.interceptors.request.use(
      async (request) => {
        if (accessToken && isTokenExpired(accessToken)) {
          await fetch('/api/v1/auth/refresh', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              dispatcher({
                type: 'LOGIN',
                payload: {
                  accessToken: res.accessToken,
                  refreshToken: res.refreshToken,
                },
              });
              request.headers['Authorization'] = 'Bearer ' + res.accessToken;
              return request;
            })
            .catch(() => {
              dispatcher({ type: 'LOGOUT' });
              navigate('/');
            });
        } else if (accessToken) {
          request.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    return axiosApiInstance;
  }, [refreshToken, accessToken, dispatcher, navigate]);
};
