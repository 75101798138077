import rootReducer from './root-reducer';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

const middlewares = [thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
