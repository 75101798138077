import styled from 'styled-components';

const ContentContainer = styled.div`
  padding-left: 20px; /* Default padding */
  padding-right: 20px; /* Default padding */

  @media (min-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1024px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

export default ContentContainer;
