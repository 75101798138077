import { Route, Routes } from 'react-router-dom';
import React from 'react';
import CalendarRoutes from '../calendar/CalendarRoutes';
import NotFound from '../notfound/NotFound';
import WorkInProgress from '../workinprogress/WorkInProgress';

export enum RootPaths {
  ROOT = '',
  CALENDAR = '/calendar',
}

const GlosslyRoutes: React.FC = () => (
  <>
    <Routes>
      <Route path={RootPaths.ROOT} element={<WorkInProgress />} />
      <Route path={RootPaths.CALENDAR} element={<CalendarRoutes />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  </>
);

export default GlosslyRoutes;
