import { IAppointmentResource } from '../model/IAppointmentResource';
import { IAppointment } from '../model/IAppointment';
import { IAppointmentEvent } from '../model/IAppointmentEvent';

export const mapToResources = (
  appointments: IAppointment[],
): IAppointmentResource[] => {
  const seen = new Set<number>();
  return appointments.reduce<IAppointmentResource[]>((acc, appointment) => {
    if (!seen.has(appointment.employee.id)) {
      seen.add(appointment.employee.id);
      acc.push({
        group_id: appointment.employee.id,
        group_name: appointment.employee.name,
      });
    }
    return acc;
  }, []);
};

export const mapToEvents = (
  appointments: IAppointment[],
): IAppointmentEvent[] =>
  appointments.map((appointment) => ({
    id: appointment.id,
    date_from: new Date(appointment.date_from),
    date_to: new Date(appointment.date_to),
    title: appointment.client.name,
    group_id: appointment.employee.id,
  }));
