import React, { Dispatch, useState } from 'react';
import { ILoginResponse } from './ILoginResponse';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { AxiosResponse } from 'axios';
import { AuthActions } from './actions/authActions';
import { useAppDispatch } from '../../app/hooks/hooks';
import { useAxios } from '../../services/axios';

const LoginModal = ({ onModalClose }: { onModalClose: () => void }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const authDispatch: Dispatch<AuthActions> = useAppDispatch();
  const authApi = useAxios();

  const handleLogin = async () => {
    await authApi
      .post<ILoginResponse>('/auth', { login, password })
      .then((response) => successLogin(response))
      .catch(() => console.error('error login'));
  };

  const successLogin = (response: AxiosResponse<ILoginResponse>) => {
    authDispatch({
      type: 'LOGIN',
      payload: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      },
    });
    navigate('/calendar');
  };

  return (
    <div className="modalBackdrop">
      <div className="modalContent">
        <button onClick={onModalClose} className="closeButton"></button>
        <input
          type="text"
          placeholder="Login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className={'loginButtonModal buttonStyle'}
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
