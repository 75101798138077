import { AuthActions } from '../actions/authActions';

type AuthState = {
  isLogged: boolean;
  accessToken: string;
  refreshToken: string;
};

const initialState: AuthState =
  localStorage.getItem('authState') != null
    ? JSON.parse(localStorage.getItem('authState') as string)
    : {
        isLogged: false,
        accessToken: '',
        refreshToken: '',
      };

const authReducer = (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case 'LOGIN':
      const loginState = {
        ...state,
        isLogged: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
      localStorage.setItem('authState', JSON.stringify(loginState));
      return loginState;
    case 'LOGOUT':
      localStorage.removeItem('authState');
      return {
        ...state,
        isLogged: false,
        accessToken: '',
        refreshToken: '',
      };
    default:
      return state;
  }
};

export default authReducer;
